<template>
  <div class="alarm-order-container d-flex flex-grow-1">
    <div id="alarm-order" class="alarm-order d-flex flex-grow-1"></div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  name: 'alarm-order',
  props: {
    alarmInfo: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      alarmInfoMap: '',
      // eslint-disable-next-line no-undef
      mapCenter: new T.LngLat(117.010429382324, 36.6744613647461),
      bermudaTriangle: null,
      infoWin: '',
      locSite: '',
      popup: '',
    };
  },
  mounted() {
    this.changeCenter(this.alarmInfo);
    this.initMap();
    this.initInfoWin();
  },
  watch: {
    alarmInfo: {
      deep: true,
      handler(newVal) {
        this.changeCenter(newVal);
      },
    },
    mapCenter: {
      deep: true,
      handler(newVal) {
        if (this.alarmInfoMap) {
          this.alarmInfoMap.panTo(newVal);
          this.initInfoWin();
        }
      },
    },
    locSite() {
      this.initInfoWin();
    },
  },
  methods: {
    ...mapMutations(['changeHisActions', 'changeWorkId']),
    getZoomSize(type) {
      switch (type) {
        case 1:
          return 10;
        case 2:
          return 9;
        case 3:
          return 15;
        default:
          return 5;
      }
    },
    changeCenter(alarmInfo) {
      if (!alarmInfo) {
        return;
      }
      // eslint-disable-next-line no-undef
      this.mapCenter = new T.LngLat(
        parseFloat(alarmInfo.Lng),
        parseFloat(alarmInfo.Lat),
      );
    },
    initMap() {
      const imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?'
        + 'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles'
        + '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0b9199b328f9862d3ec9c14d8c020f19';
      // eslint-disable-next-line no-undef
      const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
      // eslint-disable-next-line no-undef
      this.alarmInfoMap = new T.Map('alarm-order', {
        center: this.mapCenter,
        minZoom: 10,
        maxZoom: 18,
        zoom: 17,
        layers: [lay],
      });
    },
    initInfoWin() {
      if (this.infoWin) {
        this.alarmInfoMap.removeOverLay(this.infoWin);
        this.infoWin = '';
      }
      const infoContainer = document.createElement('div');
      infoContainer.className = 'warn-info';
      const titleContainer = document.createElement('div');
      titleContainer.innerHTML = `<div class="prop-header font-weight-bold cursor-pointer" style="cursor:pointer;font-size: 1.4rem;">${this.alarmInfo.WarningReason}</div>`;
      infoContainer.appendChild(titleContainer);
      const contentContainer = document.createElement('div');
      const contentString = `<div class="d-flex justify-content-between my-1" style="font-size: 1.1rem;font-weight:bold;min-width:20rem;"><div class="prop-name" >所属项目:</div><div class="prop-value text-danger">${
        this.alarmInfo.StationName
      }</div></div>
        <div class="d-flex justify-content-between my-1 " style="font-size: 1.1rem;font-weight:bold;min-width:20rem;"><div class="prop-name">设备名称:</div><div class="prop-value text-danger" >${
  this.alarmInfo.EquipmentName
}</div></div>
        <div class="d-flex justify-content-between my-1" style="font-size: 1.1rem;font-weight:bold;min-width:20rem;"><div class="prop-name">报警值:</div><div class="prop-value"><span class="text-danger">${parseFloat(
    this.alarmInfo.WarningValue,
  )}${this.alarmInfo.UnitName ? this.alarmInfo.UnitName : ''}<span></div></div>
        <div class="d-flex justify-content-between my-1" style="font-size: 1.1rem;font-weight:bold;min-width:20rem;"><div class="prop-name">报警时间:</div><div class="prop-value text-danger">${
  this.alarmInfo.CreateTime
}</div></div>
        <div class="my-1" style="font-size: 1.1rem;font-weight:bold;min-width:20rem;"><div class="prop-name text-left">报警地址:</div><div class="prop-value text-danger text-right">${
  this.alarmInfo.StationName + this.alarmInfo.EquipmentName
}</div></div>`;
      const detailContainer = document.createElement('div');
      detailContainer.className = 'd-flex justify-content-end';
      detailContainer.innerHTML = '<span class="cursor-pointer" style="font-size: 1.1rem;font-weight:bold;">详情</span>';
      contentContainer.innerHTML = contentString;
      detailContainer.addEventListener('click', this.jumpToOrderDetail);
      infoContainer.appendChild(contentContainer);
      infoContainer.appendChild(detailContainer);
      // eslint-disable-next-line no-undef
      this.infoWin = new T.InfoWindow(infoContainer, {
        minWidth: 500,
        maxWidth: 1600,
      });
      this.infoWin.setLngLat(this.mapCenter);
      // 显示详情点击事件
      this.alarmInfoMap.addOverLay(this.infoWin);
      this.infoWin.update();
    },
    jumpToOrderDetail(e) {
      e.preventDefault();
      this.$emit('closeOrder');
      this.$router.push({
        name: 'work-particulars',
        params: { eId: this.alarmInfo.TabIDstr, oId: 'no' },
      });
    },
  },
};
</script>
<style scoped>
.prop-name {
  min-width: 5rem;
}
.alarm-order,
.warn-info {
  color: rgb(101, 101, 247);
  width:500px;
}
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}
</style>
