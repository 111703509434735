<template>
  <el-container class="home">
    <el-header class="home-header full-bg dark">
      <Header @isNavCollapse="toggleNavsListener"></Header>
    </el-header>
      <el-main style="overflow:hidden" class="d-flex flex-column bg-dark">
        <router-view class="none-height flex-grow-1"></router-view>
      </el-main>
      <el-dialog :visible.sync="dialogVisible" title="新告警">
        <AlarmOrder :alarmInfo="alarmInfo"
        @closeOrder="dialogVisible=false"></AlarmOrder>
      </el-dialog>
  </el-container>
</template>
<script>
import Header from '@/components/home/headers/header.vue';
import AlarmOrder from '@/views/alarm-order.vue';
import { getNewAlarm } from '@/api/project/initiative-repair';

export default {
  name: 'Home',
  components: {
    Header,
    AlarmOrder,
  },
  data() {
    return {
      isNavCollapse: false,
      dialogVisible: false,
      alarmTimeout: null,
      alarmInfo: null,
    };
  },
  created() {
    this.requestProjectList();
  },
  computed: {
    // 项目列表
    projectList() {
      return this.$store.getters.projectList;
    },
    // 选中的项目
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  watch: {
    projectList() {
      this.requestProjectList();
    },
  },
  methods: {
    requestNewAlarm() {
      clearTimeout(this.alarmTimeout);
      getNewAlarm().then((res) => {
        console.log(res);
        this.dialogVisible = true;
        this.alarmInfo = res;
      }).catch(() => {
      }).then(() => {
        this.alarmTimeout = setTimeout(this.requestNewAlarm, 5000);
      });
    },
    toggleNavsListener(isNavCollapse) {
      this.isNavCollapse = isNavCollapse;
    },
    requestProjectList() {
      if (this.projectList === null) {
        this.$store.dispatch('projects/projectList');
        return;
      }
      const [selectProject] = this.projectList;
      console.log('selectProject', selectProject);
      this.$store.commit('projects/SELECT_PROJECT', selectProject);
    },
  },
};
</script>
<style scoped>
.home-header{
  background-color: #237ae4;
  border-bottom: 4px solid #25c1ff;
}
</style>
