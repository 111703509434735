<template>
  <el-row style="height: 100%" type="flex" align="center">
    <el-col
      :span="8"
      class="d-flex align-items-center justify-content-start text-white"
      v-on:click.stop="toggleNavs"
    >
      <div class="logo-slogan d-flex align-items-center ">
        <img class="header-logo" src="../../../assets/images/slogan/logo.png" />
        <span class="header-words"></span>
      </div>
    </el-col>
    <el-col :span="8" class="d-flex align-items-center justify-content-center">
      <span class="text-white system-name">{{systemName}}</span>
    </el-col>
    <el-col
      :span="8"
      type="flex"
      class="d-flex align-items-center right-container justify-content-end"
    >
      <div class="text-white d-flex align-items-center">
          <img
            class="warn-icon header-icon"
            src="../../../assets/images/navs/time.png"
          />
        <span style="margin-left: 4px">{{ dateTime }}</span>
      </div>
<div class="d-flex text-white align-items-center">
        <img
          class="quit-icon cursor-pointer header-icon"
          v-on:click="returnHome"
          src="../../../assets/images/navs/home.png"
        />
      </div>
      <div class="d-flex text-white align-items-center">
        <img
          class="quit-icon cursor-pointer header-icon"
          v-on:click="quitSystem"
          src="../../../assets/images/navs/quit.png"
        />
      </div>
    </el-col>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      class="dark"
    >
      <span>是否退出系统</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="sureQuitSys"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-row>
</template>
<script>
import { formatDate } from '@/utils/utils';
import { getPersonalInfo, getWarnCount } from '@/api/personal-center/setting';
import { BASE_URL } from '@/utils/request/request';
import { SYS_NAME } from '@/utils/consts';

export default {
  name: 'home-header',
  data() {
    return {
      systemName: SYS_NAME,
      dialogVisible: false,
      baseUrl: BASE_URL,
      formatDate,
      isCollapse: true,
      dateTime: formatDate(new Date(), 'yyyy/MM/dd HH:mm:ss'),
      info: {},
      warnCount: 0,
    };
  },
  created() {
    this.requestWarnCount();
    this.getDateTime();
    this.requestPersonalInfo();
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
    skinType() {
      return this.$store.getters.skinType;
    },
  },
  methods: {
    showWarnList() {
      this.$router.push({
        name: 'alarm-table',
      });
    },
    selectSkin(type) {
      this.$store.commit('app/SKIN_TYPE', type);
    },
    requestPersonalInfo() {
      getPersonalInfo({
        UIDstr: this.accountId,
      }).then((res) => {
        [this.info] = res.list;
      });
    },
    requestWarnCount() {
      getWarnCount().then((res) => {
        this.warnCount = parseInt(res.Count, 10);
      }).catch(() => {
      }).then(() => {
        setTimeout(this.resquestWarnCount, 5 * 1000);
      });
    },
    // 退出系统
    quitSystem() {
      this.dialogVisible = true;
    },
    // 返回首页
    returnHome() {
      this.$router.push({
        name: 'guid-page',
      });
    },
    toggleNavs() {
      this.isCollapse = !this.isCollapse;
      this.$emit('isNavCollapse', this.isCollapse);
    },
    getDateTime() {
      this.dateTime = formatDate(new Date(), 'yyyy/MM/dd HH:mm:ss');
      setTimeout(this.getDateTime, 1000);
    },
    sureQuitSys() {
      this.dialogVisible = false;
      this.$store
        .dispatch('user/logout', null, { root: true })
        .then(() => {
          this.$router.replace({
            name: 'Login',
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCommand(command) {
      switch (command) {
        case 0:
          this.showDialog(command);
          break;
        default:
          break;
      }
    },
    routeToPersonalSetting() {
      this.$route.push({
        name: 'personal-info-setting',
      });
    },
    showDialog(command) {
      console.log('dialogVisible');
      this.$emit('dialogVisible', command);
    },
  },
};
</script>
<style scoped>
.logo-slogan {
  font-size: 26px;
}
.header-logo {
  height: 22px;
}
.header-slogan {
  height: 32px;
}
.header-words {
  margin-left: 1rem;
  line-height: 1;
}
.split-line {
  width: 1px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.65);
  margin: 0 10px;
}
.right-container {
  font-size: 16px;
}
.header-icon {
  font-size: 24px;
}
.quit-icon {
  margin-left: 1rem;
  width:1.4rem;
  height:auto;
}
.text-darkblue {
  color: #2e9bf3;
}
.system-name{
  font-size: 1.8rem;
  font-weight: bold;
}
</style>
