import { request } from '@/utils/request/request';
// 用户信息设置
export const USER_SETTING = 'api/APP/v1/UserEdit';
// 更改个人信息
export function modifyPersonalInfo(formData, params) {
  return request({
    url: USER_SETTING,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 修改密码
export function changePasswords(data) {
  return request({
    url: 'api/APP/v1/ChangePassWord',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    params: data,
  });
}
// 获取个人信息
export function getPersonalInfo(data) {
  return request({
    url: 'api/APP/v1/Users',
    method: 'GET',
    params: data,
  });
}

// 获取短信验证码
export function getSmsCode(params) {
  return request({
    url: 'api/APP/v1/GetVerificationCodeForUpdatePhone',
    method: 'GET',
    params,
  });
}
export function getWarnCount() {
  return request({
    url: 'api/WF/v1/GetWarningCount',
    method: 'GET',
  });
}
